<template>
  <div class="service">

    <div class="container">
      <div class="row px-3">
        <div class="col-sm-3 py-2 text-center text-muted border border-bottom-0">
          <i class="fad fa-ticket-alt"></i><span class="pl-2">Ticket de servicio</span>
        </div>
      </div>
    </div>

    <div class="container animate__animated animate__fadeIn">

      <hr class="mt-0">

      <div class="row px-4 animate__animated animate__fadeIn">
        <div class="col-sm-6 px-0">
          <p class="h5 text-primary">Ticket {{ ticket.id }}</p>
          <p v-if="ticket.group_id == 2" class="mb-0">Puerto Vallarta</p>
          <p v-if="ticket.group_id == 4" class="mb-0">Plaza de la Tecnología</p>
          <p class="mb-0">Fecha de registro: {{ ticket.date_added }}</p>
          <p class="mb-0">
            <span :class="ticket.status_class" class="pr-2"><i class="fas fa-circle"></i></span>
            <span :class="ticket.status_class">{{ ticket.status }}</span>
          </p>
        </div>
        <div class="col-sm-6 px-0 text-right">
          <p class="h5 text-primary">Cliente</p>
          <p class="mb-0">Nombre: {{ ticket.customer_name }}</p>
          <p class="mb-0">Teléfono: {{ ticket.customer_phone }}</p>
          <p class="mb-0">Email: {{ ticket.customer_email }}</p>
        </div>
      </div>

      <div class="row px-4 animate__animated animate__fadeIn">
        <div class="col-12 px-0">
          <hr>
          <p class="h5 text-primary">Listado de Productos</p>
        </div>
      </div>

      <div v-for="(item, key) in ticket.product_list" :key="key" class="row px-4 animate__animated animate__fadeIn">
        <div class="col-12">
          <p class="mb-0"><strong>{{item.type}}</strong></p>
          <p class="mb-0"><strong>Producto: {{ item.name }}</strong></p>
          <p class="mb-0">Descripción: {{ item.description }}</p>
          <p class="mb-0" v-if="item.subtotal > 0">Precio: $ {{ item.price }}</p>
          <hr>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import settings from '../settings'

export default {
  name: 'Service',
  data() {
    return {
      token: '',
      ticket_id: '',
      ticket: ''
    }
  },
  methods: {
    get_ticket() {
      axios.get(
        settings.api().endpoint + settings.api().path + '/get_ticket/' + this.ticket_id, 
        { headers: { xwt: this.token } }
      ).then( (r) => {
        this.ticket = r.data.data
        console.log(this.ticket)
      })
    }
  },
  mounted() {
    this.token = this.$store.getters.get_token
    this.ticket_id = this.$route.params.id
    this.ticket = ''

    this.get_ticket()
  },
}
</script>
